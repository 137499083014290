import * as fa from "@fortawesome/free-solid-svg-icons";
import { IRouteMap } from "Infrastructure/RouteConfiguration";
import Dashboard from "Views/Private/Dashboard";
import MonthlyExpenses from "./Private/MonthlyExpenses";


const PrivateRoutes : IRouteMap = {
    Root: {
        path: "/",
        title: "Dashboard",
        element: Dashboard,
        isMenu: true,
        menuIcon: fa.faDashboard
    },
    Monthly: {
        path: "/monthly",
        title: "Contas Mensais",
        element: MonthlyExpenses,
        isMenu: true,
        menuIcon: fa.faCalendar
    }
}

export default PrivateRoutes;