import { faArrowDown, faArrowUp, faDotCircle, faLineChart, faLinesLeaning, faMinus, faUsersLine } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Field, FieldTypes } from "Infrastructure/ModelStructure";
import dayjs from "dayjs";
import { Timestamp } from "firebase/firestore";


export const currentDate = (): string => {
    return dayjs().format('YYYY-MM-DD');
}

export const currencyFormater = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
});

export const currencyToNumber = (value: string) => {
    let v = value;
    if (value.toString()[0] === "R") {
        v = value.toString().replace("R$", "").replace(".", "").replace(",", ".");
    }
    return Number(v);
}

export const numberToCurrency = (value: number) => {
    const number = currencyFormater.format(value);
    return number;
}

export const formatDate = (date: string) => {
    const dateElements = date.split('/');
    const year = Number(dateElements[2]);
    const month = Number(dateElements[1]);
    const day = Number(dateElements[0]);

    const jsDate = new Date(year, month - 1, day);
    return dateFormater.format(jsDate);
}

export const dateFormater = new Intl.DateTimeFormat('pt-BR', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
});


export const currentDateInput = currentDate();

export const currentMonthInput = currentDateInput.split('-')[0] + "-" + currentDateInput.split('-')[1];

export const uid = () => Date.now().toString(36) + Math.random().toString(36);


export const formatedDatetoInputDate = (month?: string) => {
    if (!month) {
        return currentDateInput;
    }
    return month.split('/')[2] + "-" + month.split('/')[1] + "-" + month.split('/')[0];
}

export const formatDateInputToDateString = (date: string | Date) => {
    if (typeof date === 'string') {
        const dateElements = date.split('-');
        const year = Number(dateElements[0]);
        const month = Number(dateElements[1]);
        const day = Number(dateElements[2]);

        const jsDate = new Date(year, month - 1, day);
        return dateFormater.format(jsDate);
    }

    return dateFormater.format(date);

}

export const formatedMonthtoInputMonth = (month?: string) => {
    if (!month) {
        return currentMonthInput;
    }
    return month.split('/')[0] + "-" + month.split('/')[1];
}

export type Any = {
    [key: string]: any;
}

export const inputDateToDatabase = (date: string | Date) => {
    if (typeof date === 'string') {
        const dateElements = date.split('-');
        const year = Number(dateElements[0]);
        const month = Number(dateElements[1]);
        const day = Number(dateElements[2]);

        const jsDate = new Date(year, month - 1, day);
        return jsDate;
    }

    return date;
}

export const getDateFromDatabaseToJsDate = (d: any) => {
    if(d?.seconds) {
        return new Timestamp(d?.seconds ?? 0, d?.nanoseconds ?? 0).toDate();
    }
    return dayjs(d).toDate();
}


export const getDateFromDatabaseToView = (d: any) => {
    if(d?.seconds) {
        const date = new Timestamp(d?.seconds ?? 0, d?.nanoseconds ?? 0).toDate();
        return date.toLocaleDateString('pt-BR');
    }
    return dayjs(d).format('DD/MM/YYYY');
}

export const TimestampToDate = (d: any) => {
    return new Timestamp(d?.seconds ?? 0, d?.nanoseconds ?? 0).toDate();
}

export const DateToTimestamp = (d: any) => {
    return new Timestamp(d.getTime() / 1000, 0);
}

export const mapDataRequest = (data: any, fields: Field[]) => {
    const result: any = {
        id: data.id ?? '',
    };
    fields.forEach(field => {
        if (field.type === FieldTypes.Currency)
            result[field.id] = currencyToNumber(data[field.id] ?? '');
        else if (field.type === FieldTypes.Checkbox)
            result[field.id] = Boolean(data[field.id] ?? false);
        else if (field.type === FieldTypes.Number)
            result[field.id] = Number(data[field.id] ?? 0);
        else if (field.type === FieldTypes.Date)
            result[field.id] = inputDateToDatabase(data[field.id]);
        else
            result[field.id] = formatString(data[field.id]) ?? '';
    });
    return result;
}

export const formatString = (value?: string) => {
    if (!value) return value;
    let formatted = value.normalize('NFD').replace(/[\u0300-\u036f]/g, "");
    //remove multiple spaces
    formatted = formatted.replace(/\s{2,}/g, ' ');
    //Capital case only on first letter
    formatted = formatted.charAt(0).toUpperCase() + formatted.slice(1).toLowerCase();
    return formatted;
}

export const mapDataResponse = (data: any, fields: Field[]) => {
    const result: any = {};
    fields.forEach(field => {
        if (field.type === FieldTypes.Date) {
            result[field.id] = data[field.id] ? getDateFromDatabaseToView(data[field.id]) : field.defaultValue;
        }
        else {
            result[field.id] = data[field.id] ?? field.defaultValue ?? '';
        }
    });
    return result;
}

export const currentTimestamp = () => {
    return new Timestamp(new Date().getTime() / 1000, 0);
}

export const viewData = (res: any, field: Field) => {
    switch (field.type) {
        case FieldTypes.Currency:
            return numberToCurrency(res[field.id]);
        case FieldTypes.Date:
            return getDateFromDatabaseToView(res[field.id]);
        case FieldTypes.Checkbox:
            return res[field.id] ? "Sim" : "Não";
        default:
            return res[field.id];
    }
}


export const fixDecimals = (input: string | number) => {
    let value = input?.toString() ?? '';

    if(value === '') return value;

    if (value.slice(0, 1) === '.') {
        value = '0' + value;
    }

    if (!value.includes('.')) {
        value = value + '.00';
    }

    if (value.split('.')[1].length < 2) {
        value = value + '000';
    }

    value = value.slice(0, value.indexOf('.') + 3);

    return value;
}

export const formatRealToInput = (value: number) => {
    let str = value?.toString() ?? '';
    //remove . and replace , to .
    str = str.replace(/\./g, ',');
    return str;
}

export const formatRealToDatabase = (str: string) : string => {
    //only numbers and , are allowed
    let int = str.replace(/[^0-9,.]/g, '');
    //replace , to .
    int = int.replace(/\,/g, '.');

    //dont allow multiple , in the string
    if (int.split('.').length > 2) {
        int = int.slice(0, int.lastIndexOf('.'));
    }
    //dont allow more than 2 decimal places
    if (int.split('.').length > 1) {
        let decimal = int.split('.')[1];
        if (decimal.length > 2) {
            int = int.slice(0, int.indexOf('.') + 3);
        }
    }

    return int;
}


export type SortDirection = "asc" | "desc";
export const dataSort = (data: any[], sortBy: string, sortDirection: SortDirection) => {
    const getRelativeString = (data: any): string => {
        let str = data.toDate?.().toLocaleDateString() ?? data;
        str = str.toString?.() ?? str;
        str = str.toLowerCase?.() ?? str;
        return str;
    }

    return data.sort((a: any, b: any) => {
        let _a = a[sortBy];
        let _b = b[sortBy];
        if (typeof _a === "number" && typeof _b === "number") {
            return sortDirection === "asc" ? _a - _b : _b - _a;
        }

        if (typeof _a === "boolean" && typeof _b === "boolean") {
            return sortDirection === "asc" ? _a ? -1 : 1 : _a ? 1 : -1;
        }

        _a = getRelativeString(a[sortBy]);
        _b = getRelativeString(b[sortBy]);
        if (sortDirection === "asc") {
            if (_a > _b) return 1;
            if (_a < _b) return -1;
            return 0;
        } else {
            if (_a > _b) return -1;
            if (_a < _b) return 1;
            return 0;
        }
    });
}

export const getSortIcon = (field: string, sortBy: string, SortDirection: SortDirection) => {
    if (sortBy !== field) {
        return <FontAwesomeIcon icon={faMinus} />;
    }
    if (SortDirection === "asc") {
        return <FontAwesomeIcon icon={faArrowUp} />;
    } else {
        return <FontAwesomeIcon icon={faArrowDown} />;
    }
}


export const dataSearch = (data: any[], search: string) => {
    const getRelativeString = (data: any): string => {
        let str = data.toDate?.().toLocaleDateString() ?? data;
        str = str.toString?.() ?? str;
        str = str.toLowerCase?.() ?? str;
        return str;
    }

    const filterred = data.filter(d => {
        return Object.keys(d).some(key => {
            let str = getRelativeString(d[key]);
            return str.includes(search.toLowerCase());
        });
    });

    return filterred;

}