import { FieldTypes, IModelSetup } from "../Infrastructure/ModelStructure";
import { currentDateInput } from "Infrastructure/utils";
import * as fa from "@fortawesome/free-solid-svg-icons";
import RequestHandler from "../Infrastructure/RequestHandler";

const endpoint = "expense";

export enum ExpenseFields {
    Id = 'id',
    Date = 'date',
    Value = 'value',
    Type = 'type',
    Category = 'category',
    Description = 'description',
}


const CategoryAutoComplete = () => {
    return (new RequestHandler(endpoint)).all("category_autocomplete").then((list) => {
        const optionList = list.map((item: any) => {
            return item[ExpenseFields.Category]
        })
        return optionList;
    })
}


const TypeAutoComplete = () => {
    return (new RequestHandler(endpoint)).all("type_autocomplete").then((list) => {
        const optionList = list.map((item: any) => {
            return item[ExpenseFields.Type]
        })
        return optionList;
    })
}

const DescriptionAutoComplete = () => {
    return (new RequestHandler(endpoint)).all("description_autocomplete").then((list) => {
        const optionList = list.map((item: any) => {
            if(item[ExpenseFields.Category] !== "Mensal") return null;
            return item[ExpenseFields.Description]
        })
        return optionList;
    })
}




export const Expense: IModelSetup = {
    endpoint: endpoint,
    title: "Despesa",
    plural: "Despesas",
    article: "a",
    defaultPath: process.env.ROUTE_EXPENSES ?? "/Expenses",
    orderBy: ExpenseFields.Date,
    orderDirection: "desc",
    createOnList: true,
    isMenu: true,
    menuIcon: fa.faMoneyBill,
    fields: {
        [ExpenseFields.Date]: {
            question: 'Qual a data da despesa?',
            label: "Data",
            id: ExpenseFields.Date,
            type: FieldTypes.Date,
            required: true,
            defaultValue: new Date(),
            fieldSize: 4,
        },
        [ExpenseFields.Value]: {
            question: 'Qual o valor da despesa?',
            label: "Valor",
            id: ExpenseFields.Value,
            type: FieldTypes.Currency,
            required: true,
            fieldSize: 4,
        },
        [ExpenseFields.Category]: {
            question: 'Qual a categoria da despesa?',
            label: "Categoria",
            id: ExpenseFields.Category,
            type: FieldTypes.AutoComplete,
            autocompleteList: CategoryAutoComplete,
            required: true,
            fieldSize: 4,
        },
        [ExpenseFields.Type]: {
            question: 'Como a despesa foi paga?',
            label: "Pagamento",
            id: ExpenseFields.Type,
            type: FieldTypes.AutoComplete,
            autocompleteList: TypeAutoComplete,
            required: true,
            fieldSize: 4,
        },
        [ExpenseFields.Description]: {
            question: 'Qual a descrição da despesa?',
            label: "Descrição",
            id: ExpenseFields.Description,
            type: FieldTypes.AutoComplete,
            autocompleteList: DescriptionAutoComplete,
            required: true,
            fieldSize: 6,
        },
    }
}