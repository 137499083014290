import { IModelSetup } from "Infrastructure/ModelStructure";
import { Expense } from "./Expense";
import { Invoice } from "./Invoice";
import { Payments } from "./Payments";

export const Repositories : IModelSetup[] = [
    Expense,
    // Monthly,
    // Invoice,
    // Payments,
]