import React, { useEffect, useState } from "react";
import { auth, googleProvider } from "Infrastructure/firebase-config";
import { createUserWithEmailAndPassword, signInWithEmailAndPassword, signInWithPopup } from "firebase/auth";
import { User } from "firebase/auth";
import Loading from "Templates/Components/Loading";
import {useAuthState} from 'react-firebase-hooks/auth';


const authContext = React.createContext({
    Logout: (): Promise<void> => Promise.resolve(),
    Login: (email: string | undefined, password: string | undefined): Promise<void> => Promise.resolve(),
    Register: (email: string | undefined, password: string | undefined): Promise<void> => Promise.resolve(),
    isLoggedIn: (): boolean => false,
    userData: (): User | null | undefined => undefined,
    LoginWithGoogle: (): Promise<void> => Promise.resolve()
});

type Props = {
    children?: React.ReactNode;
};

export const useAuth = () => {
    return React.useContext(authContext);
}

export const AuthProvider = ({ children }: Props) => {
    const [loading , setLoading] = useState(true);
    const [authUser] = useAuthState(auth);

    useEffect(() => {
        auth.authStateReady().then(() => {
            setLoading(false);
        });
    }, []);

    const Logout = async () => {
        setLoading(true);
        await auth.signOut();
        window.location.href = "/Login";
    }

    
    const LoginWithGoogle = async () => {
        await signInWithPopup(auth, googleProvider);
    }


    const Login = async (email: string | undefined, password: string | undefined, redirect?: string | undefined) => {
        if (!email || !password) throw new Error("Email or password is empty");
        await signInWithEmailAndPassword(auth, email, password);
    }

    const Register = async (email: string | undefined, password: string | undefined) => {
        if (!email || !password) throw new Error("Email or password is empty");
        await createUserWithEmailAndPassword(auth, email, password);
    }

    const isLoggedIn = () => authUser !== null;

    const userData = () => authUser;

    if (loading) {
        return <Loading />
    }

    return (
        <authContext.Provider value={{ Logout, Login, Register, isLoggedIn, userData, LoginWithGoogle}}>
            {children}
        </authContext.Provider>
    );
}