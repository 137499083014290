import { Alert } from "@mui/material";

export type AlertOptions = {
    type: 'success' | 'error' | 'warning' | 'info';
    message: any;
    timeout?: number;
    hide?: boolean;
    variant?: 'filled' | 'outlined' | 'standard';
    onClose?: () => void;
}


export const AlertItem = (props: AlertOptions) => {
    if (props.hide) {
        return null;
    }
    
    return (
        <Alert severity={props.type} sx={{ marginBottom: 1 }}>
            {props.message}
        </Alert>
    )
}
