import { SortDirection, currencyFormater, dataSearch, dataSort, getSortIcon } from 'Infrastructure/utils';
import { MonthlyRequestHandler } from 'Repository/Monthly';
import SortIcon from 'Templates/Components/SortIcon';
import TablePlaceHolder from 'Templates/Components/TablePlaceholder';
import React from 'react';
import { Card, ListGroup, Table } from 'react-bootstrap';

type ExpenseByCategoryProps = {
    categoryName: string;
    headers: { field: string, label: string }[];
    expenseRender: (expense: any, index: number) => JSX.Element;
    plural?: string;
    showPending?: boolean;
}

const ExpenseByCategory = (props: ExpenseByCategoryProps) => {
    const [search, setSearch] = React.useState<string>("");
    const [data, setData] = React.useState<any[]>([])
    const [sortBy, setSortBy] = React.useState<string>("name");
    const [sortOrder, setSortOrder] = React.useState<SortDirection>("asc");
    const [loading, setLoading] = React.useState<boolean>(true);
    const service = new MonthlyRequestHandler();

    const loadData = () => {
        service.allByCategory(props.categoryName).then((response) => {
            setData(response);
            setLoading(false);
        });
    }

    React.useEffect(() => {
        loadData();
    }, []);

    if (loading) {
        return <TablePlaceHolder rows={10} columns={9} />
    }

    const handleSort = (field: string) => {
        if (sortBy === field) {
            if (sortOrder === "asc") {
                setSortOrder("desc");
            } else {
                setSortOrder("asc");
            }
        } else {
            setSortBy(field);
        }
    }

    const sortedData = dataSort(dataSearch(data, search), sortBy, sortOrder);
    const listData = sortedData.map(props.expenseRender);

    return (
        <Card>
            <Card.Header>
                <Card.Title>Despesas {props.plural ?? props.categoryName}</Card.Title>
                <Card.Subtitle className='mtop-1'>Total {props.categoryName}: <span className='text-success'>{currencyFormater.format(data.reduce((acc, curr) => { return curr.oldExpense ? acc : acc + curr.lastPaidValue }, 0))}</span></Card.Subtitle>
                {props.showPending && <Card.Subtitle className='mtop-1'>Pendente: <span className='text-danger'>{currencyFormater.format(data.reduce((acc, curr) => { return curr.paidCurrentMonth === false && curr.oldExpense === false ? acc + curr.lastPaidValue : acc }, 0))}</span></Card.Subtitle>}
            </Card.Header>
            <Card.Body style={{
                paddingTop: 0,
                paddingBottom: 0,
                borderBottom: "1px solid #e0e0e0"
            }}>
            </Card.Body>
            <Card.Body className="p-0">
                <ListGroup variant="flush">
                    <ListGroup.Item className="p-0">
                        <input type="search" style={{ borderColor: "transparent" }} className="form-control" placeholder="Pesquisar..." value={search} onChange={e => setSearch(e.target.value)} />
                    </ListGroup.Item>
                    <Table striped bordered hover responsive>
                        <thead>
                            <tr>
                                {
                                    props.headers.map((header, index) => {
                                        return (
                                            <th key={index} onClick={() => handleSort(header.field)}>{header.label} <SortIcon field={header.field} sortBy={sortBy} SortDirection={sortOrder} /></th>
                                        )
                                    })
                                }
                            </tr>
                        </thead>
                        <tbody>
                            {
                                listData.length === 0 &&
                                <tr>
                                    <td colSpan={8} className="text-center">Nenhum registro encontrado</td>
                                </tr>
                                
                            }
                            {
                                listData.map((expense) => {
                                    return expense;
                                })
                            }
                        </tbody>
                    </Table>
                </ListGroup>
            </Card.Body>
        </Card>
    );
};


export default ExpenseByCategory;