import { Card, Placeholder } from "react-bootstrap";

const ViewPlaceholder = () => <Card>
    <Card.Header>
        <Card.Title as="h5">Carregando...</Card.Title>
    </Card.Header>
    <Card.Body>
        <Placeholder animation="glow">
            <Placeholder xs={6} bg="secondary" />
        </Placeholder>                                    <Placeholder animation="glow">
            <Placeholder xs={6} bg="secondary" />
        </Placeholder>                                    <Placeholder animation="glow">
            <Placeholder xs={6} bg="secondary" />
        </Placeholder>
    </Card.Body>
    <Card.Footer>
        <div className="flex-between">
            <Placeholder.Button>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Placeholder.Button>
            <Placeholder.Button variant="danger">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Placeholder.Button>
        </div>
    </Card.Footer>
</Card>;

export default ViewPlaceholder;