import { Placeholder, Table } from "react-bootstrap";


const TablePlaceHolder = (props : {rows: number, columns: number}) => {
    const tcolumns = Array.from({ length: props.columns }, (_, index) => index);
    const trows = Array.from({ length: props.rows }, (_, index) => index);
    return (
        <Table responsive>
            <thead>
                <tr>
                    {tcolumns.map((column, index) => {
                        return (
                        <th key={index}>
                            <Placeholder animation="glow">
                                <Placeholder xs={12} bg="secondary" />
                            </Placeholder>
                        </th>
                    )})}
                </tr>
            </thead>
            <tbody>
                {trows.map((row, index) => {
                    return (
                        <tr key={index}>
                            {tcolumns.map((column, index) => {
                                return (
                                    <td key={index}>
                                        <Placeholder animation="glow">
                                            <Placeholder xs={12} bg="secondary" />
                                        </Placeholder>
                                    </td>
                                )
                            })}
                        </tr>
                    )
                })}
            </tbody>
        </Table>
    );
}

export default TablePlaceHolder;