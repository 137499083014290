import { IModelSetup } from "Infrastructure/ModelStructure";
import { Col, Row } from "react-bootstrap";
import { Route, Routes } from "react-router-dom";
import DynamicList from "./DynamicList";
import DynamicCreate from "./DynamicCreate";
import DynamicUpdate from "./DynamicUpdate";
import { DynamicView } from "./DynamicView";

export const DynamicEndpoints = {
    root: "/",
    new: "/New",
    edit: "/Edit/",
    view: "/"
}

const DynamicRoute = (props: IModelSetup) => {
    return (
        <Row>
            <Col>
                <Routes>
                    <Route path={DynamicEndpoints.root} element={<DynamicList {...props}/>} />
                    <Route path={DynamicEndpoints.view+":id"} element={<DynamicView {...props}/>} />
                    <Route path={DynamicEndpoints.new} element={<DynamicCreate {...props}/>} />
                    <Route path={DynamicEndpoints.edit+":id"} element={<DynamicUpdate {...props}/>} />
                </Routes>
            </Col>
        </Row>
    );
}

export default DynamicRoute;