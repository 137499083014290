import React from "react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

export interface Props {
    children?: React.ReactNode;
    className?: string;
}

const BackButton = ({ className = "" }: Props) => {
    const navigate = useNavigate();

    return (
        <Button variant="link" className={className} onClick={() => { navigate(-1) }}>
            Voltar
        </Button>
    )

}


export default BackButton;