import { Button, Card, Navbar, Table } from "react-bootstrap"
import { viewData } from "Infrastructure/utils"
import BackButton from "../BackButton"
import { NavLink, useNavigate, useParams } from "react-router-dom"
import ViewPlaceholder from "../ViewPlaceholder"
import NotFound from "Views/NotFound"
import { useEffect, useState } from "react"
import { useModal } from "Providers/ModalProvider"
import { useAlert } from "Providers/AlertProvider"
import { IModelSetup, Field, getDefaultService } from "Infrastructure/ModelStructure"
import { DynamicEndpoints } from "./DynamicRoute"

export const DynamicView = (props: IModelSetup) => {
    const requester = props.title + " - DynamicView";
    const params = useParams();
    const id = params.id as string;
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState();
    const navigate = useNavigate();
    const modal = useModal();
    const alert = useAlert()
    const service = props.service ?? getDefaultService(props.endpoint);

    useEffect(() => {
        service.get(id, requester).then((d) => {
            setData(d);
            setLoading(false);
        });

        // eslint-disable-next-line
    }, []);

    const deleteAction = () => {
        if (!data) return;
        modal.showModal({
            title: `Excluir ${props.title.toLowerCase()}`,
            message: `Deseja realmente excluir ${props.article} ${props.title.toLowerCase()}?`,
            onConfirm: () => {
                setLoading(true);
                service.delete(id, requester).then(() => {
                    alert.showAlert({
                        type: "warning",
                        message: `${props.title} excluíd${props.article} com sucesso.`
                    });
                    navigate(props.defaultPath);
                }).catch(() => {
                    alert.showAlert({
                        type: "error",
                        message: `Não foi possível excluir ${props.article} ${props.title.toLowerCase()}.`
                    });
                    setLoading(false);
                });
            }
        });
    }

    if (loading) {
        return <ViewPlaceholder />
    }

    if (!data) {
        return <NotFound />;
    }




    return <>
        <Card>
            <Card.Header>
                <Navbar>
                    <Card.Title as="h5">
                        {props.title}
                    </Card.Title>
                </Navbar>
            </Card.Header>
            <Card.Body className="p-0">
                <Table responsive>
                    <tbody>
                        {Object.keys(props.fields ?? {}).map((key, index) => {
                            const f = props.fields[key];
                            const field = f as Field;
                            return <tr key={index}>
                                <td style={{ textAlign: 'left', fontWeight: 'bold', width: '200px' }}>
                                    {field.label}
                                </td>
                                <td style={{ textAlign: 'left' }}>
                                    {viewData(data, field)}
                                </td>
                            </tr>
                        })}
                    </tbody>
                </Table>
            </Card.Body>
            <Card.Footer>
                <div className="flex-between">
                    <BackButton />
                    <div className="flex-end">
                        <NavLink to={`${props.defaultPath}${DynamicEndpoints.edit}${id}`} className="btn btn-warning mx-2">Editar</NavLink>
                        <Button onClick={deleteAction} variant="danger">Excluir</Button>
                    </div>
                </div>
            </Card.Footer>
        </Card>
    </>
}
