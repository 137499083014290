import { DynamicInputProps } from "./DynamicInput"
import dayjs from 'dayjs';
import { TextField } from "@mui/material";


const DynamicDateInput = (props: DynamicInputProps) => {
    const hasError = props.errors !== undefined && props.errors !== "";
    const OnChangeDate = (e: any) => {
        if (props.onChange) {
            if(e.target.value === "") 
                return props.onChange(props.field.id, '');
            const v = dayjs(e.target.value, "YYYY-MM-DD").toDate();
            props.onChange(props.field.id, v)
        }
    }


    const formatValue = (value: any) => {
        if (value === undefined || value === null || value === "") return "";
        return dayjs(value).format("YYYY-MM-DD");
    }

    return <TextField
        type={props.field.type}
        name={props.field.id}
        value={formatValue(props.value)}
        onChange={OnChangeDate}
        disabled={props.disabled}
        fullWidth
        size={props.small ? "small" : "medium"}
        error={hasError} 
        autoFocus={props.autoFocus}
        onKeyUp={(e) => {
            if (e.key === "Enter") {
                if (props.enterKey) props.enterKey();
            }
        }}
    />
}

export default DynamicDateInput