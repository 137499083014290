import React from 'react';
import ReactDOM from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'assets/scss/welltemplate.scss'
import { AuthProvider } from "Providers/AuthProvider";
import { ModalProvider } from 'Providers/ModalProvider';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { AppRoutes } from 'Infrastructure/RouteConfiguration';



const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);


root.render(
  <LocalizationProvider dateAdapter={AdapterDayjs}>
    <ModalProvider>
      <AuthProvider>
        <AppRoutes />
      </AuthProvider>
    </ModalProvider>
  </LocalizationProvider>
);