import { Button, Modal } from 'react-bootstrap';

export type TModal = {
    title: string;
    message: string | React.ReactNode;
    onConfirm: () => void;
    onCancel?: () => void;
    size?: "sm" | "lg" | "xl";
}

export const ModalComponent = (props: TModal) => {
    return (
        <Modal show={true} onHide={props.onCancel} size={props.size}>
            <Modal.Header>
                <Modal.Title>{props.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{props.message}</Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={props.onCancel}>
                    Cancel
                </Button>
                <Button variant="primary" onClick={props.onConfirm}>
                    Confirm
                </Button>
            </Modal.Footer>
        </Modal>
    )
}
