import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { List, ListItem, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { generateRouteMap } from "Infrastructure/RouteConfiguration";
import { NavLink, useNavigate } from "react-router-dom";


type Props = {
    className?: string;
}

const MenuList = ({ className = 'sidebar-item' }: Props) => {
    const routeMap = generateRouteMap();
    const navigate = useNavigate();
    const menuItems = Object.keys(routeMap).map((key, index) => {
        const rawItem: any = routeMap[key];
        const item: any = rawItem.model ? rawItem.model : rawItem;
        const path = item.defaultPath ?? item.path;
        const title = rawItem.model ? rawItem.model.plural : rawItem.title;
        const isMenu = item.isMenu;
        const menuIcon = item.menuIcon;
        return isMenu ? {
            path,
            title,
            isMenu,
            menuIcon,
            active: false
        } : null;
    });

    return (
        <>
            {menuItems.map((item, index) => {
                if (item) {
                    return (
                        <ListItem role="menuItem" className={className} onClick={() => navigate(item.path)} key={item.path + index}>
                            <ListItemButton role="menuItem">
                                <ListItemIcon>
                                    {item.menuIcon && <FontAwesomeIcon icon={item.menuIcon} />}
                                </ListItemIcon>
                                <ListItemText>
                                    {item.title}
                                </ListItemText>
                            </ListItemButton>
                        </ListItem>
                    )
                }
                return null;
            })}
        </>
    )
}

export default MenuList;