import { Spinner } from "react-bootstrap";

export interface Props {
    className?: string | undefined;
    children?: React.ReactNode;
}

const Loading = ({ className = "", children }: Props) => (
    <div className={`loading ${className}`}>
        <Spinner animation="border" role="loading">
            <span className="visually-hidden">Loading...</span>
        </Spinner>
        {children}
    </div>
)


export default Loading;