import { Expense } from "Repository/Expense";
import RequestHandler from "./RequestHandler";
import { capitalize } from "@mui/material";

type DataSet = {
    label: string;
    data: number[];
    backgroundColor: string;
    hidden?: boolean;
}

type ChartData = {
    labels: string[];
    datasets: DataSet[];
}


class DashboardRequestHandler extends RequestHandler {
    colors = [
        'rgba(65,121,163,0.9)',
        'rgba(82,206,144,0.9)',
        'rgba(220,98,109,0.9)',
        'rgba(232,130,93,0.9)',
        'rgba(197,206,209,0.9)',
        'rgba(177,170,235,0.9)',
        'rgba(188,143,111,0.9)',
        'rgba(255,205,143,0.9)',
        'rgba(182,215,103,0.9)',
        'rgba(87,210,218,0.9)',
        'rgba(54, 162, 235, 0.9)',
        'rgba(255, 99, 132, 0.9)',
        'rgba(255, 206, 86, 0.9)',
        'rgba(75, 192, 192, 0.9)',
    ]
    
    constructor() {
        super(Expense.endpoint);
    }


    getChartData = async (): Promise<ChartData> => {
        const data = await this.all();
        if (data.length === 0) {
            return { labels: [], datasets: [] };
        }

        const categoryByMonth = data.reduce((acc: any, expense: any) => {
            const date = expense.date.toDate?.();
            const monthYearName = capitalize(date.toLocaleString('default', { month: 'long' }) + ' ' + date.getFullYear());
            const value = Number(expense.value);
            if (!acc[monthYearName]) {
                acc[monthYearName] = {};
            }
            if (!acc[monthYearName][expense.category]) {
                acc[monthYearName][expense.category] = 0;
            }

            acc[monthYearName][expense.category] = Number(parseFloat(acc[monthYearName][expense.category] + value).toFixed(2));
            return acc;
        }, {});

        const categories = data.map((expense: any) => expense.category).filter((value: any, index: any, self: any) => self.indexOf(value) === index);
        const labels = Object.keys(categoryByMonth).reverse();
        const dataSets = categories.map((category: string) => {
            return {
                label: capitalize(category),
                data: labels.map((monthYearName: string) => categoryByMonth[monthYearName][category]),
                backgroundColor: this.colors.pop() || '',
                MinBarThickness: 50,
                hidden: false,
                minBarLength: 20,
            }
        });

        const chartData: ChartData = {
            labels: labels,
            datasets: dataSets
        };

        console.log(chartData)
        return chartData;
    }

}


export default DashboardRequestHandler;