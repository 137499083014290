import { faSadTear } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BackButton from "Templates/Components/BackButton";

const NotFound = () => (
    <div className='centered-box'>
        <div className="box bg-white-transparent text-center">
            <h1>
                <FontAwesomeIcon icon={faSadTear} />
            </h1>
            <h1>404</h1>
            <h2>Essa página não foi encontrada...</h2>
            <p>
                Mas é possível voltar para a tela anterior clicando abaixo:
            </p>
            <BackButton />
        </div>
    </div>
)

export default NotFound;