import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { GoogleAuthProvider } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getDatabase } from "firebase/database";

const firebaseConfig = {
  apiKey: "AIzaSyCjG8ZAH_DtscKnjXtIOk_NPIVFBhnynlQ",
  authDomain: "fb-goldpocket.firebaseapp.com",
  projectId: "fb-goldpocket",
  storageBucket: "fb-goldpocket.appspot.com",
  messagingSenderId: "736671772851",
  appId: "1:736671772851:web:acf21d15ef4cdb0c5640c6",
  measurementId: "G-1L899XB4F9"
};

const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const auth = getAuth(app);
export const googleProvider = new GoogleAuthProvider();
export const firestore = getFirestore(app);
export const storage = getStorage(app);
export const database = getDatabase(app);

