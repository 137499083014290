
const STORE_TIME = 1000 * 60 * 5;

export class localStorageHandler {
    private appName = "";
    private userId = "";
    private storeTime = STORE_TIME;


    constructor(name: string, id: string) {
        this.appName = name;
        this.userId = id;
    }

    validateStoredTime = () => {
        const data = this.getStoredData();
        const storedDate = new Date(data?.storedDate ?? 0);
        const currentDate = new Date();
        const diff = currentDate.getTime() - storedDate.getTime();
        if (diff > this.storeTime) {
            this.clearStoredData();
        }
    }

    isStored = () => {
        const data = this.getStoredData();
        return data !== null && data !== undefined;
    }

    storeData = (data: any) => {
        data.storedDate = new Date();
        data.userId = this.userId;
        localStorage.setItem(this.appName, JSON.stringify(data));
    }

    getStoredData = () => {
        try {
            this.validateStoredTime();
            const storedData = localStorage.getItem(this.appName);
            const data = JSON.parse(storedData ?? "[]");
            if(data.userId === this.userId) return data;
        } catch {
            this.clearStoredData();
            return null;
        }
    }


    clearStoredData = () => {
        localStorage.removeItem(this.appName);
    }


}