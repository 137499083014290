import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { DynamicForm, DynamicFormProps } from "Templates/Components/Dynamic/DynamicForm";
import { useAlert } from "Providers/AlertProvider";
import { IModelSetup, getDefaultService, getFormData } from "Infrastructure/ModelStructure";

interface IDynamicCreateProps extends IModelSetup {
    onTable?: boolean;
    refresh? : () => void;
}

const DynamicCreate = (props : IDynamicCreateProps) => {
    const navigate = useNavigate();
    const alert = useAlert()
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState(getFormData(props.fields));
    const service = props.service ?? getDefaultService(props.endpoint);

    const onSubmit = () => {
        setLoading(true);
        service.create(data).then(() => {
            alert.showAlert({
                type: "success",
                message: `${props.title} criad${props.article} com sucesso.`
            });
            if(props.refresh) props.refresh();
            else navigate(props.defaultPath);
        }).catch((e: any) => {
            console.error(e);
            alert.showAlert({
                type: "error",
                message: `Não foi possível criar ${props.article} ${props.title.toLowerCase()}.`
            });
        }).finally(() => {
            setLoading(false);
        });
    }

    useEffect(() => {
        setLoading(true);
        setData(getFormData(props.fields));
        setLoading(false);
    }, [props.fields])

    if(loading) return null;
    
    const form : DynamicFormProps = {
        model: props,
        title: `Criar nov${props.article} ${props.title.toLowerCase()}`,
        actionLabel: "Criar!",
        loading: loading,
        handleSubmit: onSubmit,
        onChangeField: setData,
        data: data,
        size: 6,
        fields: props.fields,
    }

    return <DynamicForm {...form} tableForm={props.onTable} />
}

export default DynamicCreate;