import ExpenseCategoryChart from "Templates/Components/Charts/ExpenseCategoryChart";



const Dashboard = () => {
    return (
        <>
            <ExpenseCategoryChart />
        </>
    )
}

export default Dashboard;