import { useMemo, useState } from "react";
import { DynamicInputProps } from "./DynamicInput"
import { Autocomplete, TextField, capitalize } from "@mui/material"

export interface IOption {
  label: string;
}

const DynamicAutocomplete = (props: DynamicInputProps) => {
  const [loading, setLoading] = useState(true);
  const [options, setOptions] = useState<IOption[]>([]);
  const hasError = props.errors !== undefined && props.errors !== "";

  const OnChange = (event: any, value: any) => {
    if (typeof value === 'object') value = value?.label ?? '';
    props.onChange(props.field.id, capitalize(value))
  }

  const loadOptions = () => {
    setLoading(true);
    if (!props.field.autocompleteList) {
      setLoading(false);
    }
    else {
      const requester = props.field.id + " - DynamicAutocomplete";
      props.field.autocompleteList(requester).then((optionList: string[]) => {
        //remove duplicates and empty strings from optionList
        const uniqueOptions = optionList.filter((v, i, a) => a.indexOf(v) === i && v !== "" && v).map((o) => {
          return { label: o }
        });

        setOptions(uniqueOptions);
        setLoading(false);
      })
    }
  }

  useMemo(() => {
    loadOptions()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.field.id])

  if (loading) return <></>;

  return <Autocomplete
    className={hasError ? "autocompleteError" : ""}
    disablePortal
    freeSolo
    id={props.field.id}
    options={options}
    autoFocus={props.autoFocus}
    value={props.value}
    inputValue={props.value}
    onChange={OnChange}
    onInputChange={OnChange}
    renderInput={(params) => <TextField {...params} />}
    size={props.small ? "small" : "medium"}
    onKeyUp={(e) => {
      if (e.key === "Enter") {
        if (props.enterKey) props.enterKey();
      }
    }}
  />
}

export default DynamicAutocomplete
