import React from 'react';
import {
  Chart as ChartJS,
  BarElement,
  Title,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { currencyFormater } from 'Infrastructure/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter, faFilterCircleXmark } from '@fortawesome/free-solid-svg-icons';
import Loading from '../Loading';
import { capitalize } from '@mui/material';
import { Card, Col, Row } from 'react-bootstrap';
import SelectList, { ISelectListProps } from './GraphSelectList';
import { _DeepPartialObject } from 'chart.js/dist/types/utils';
import DashboardRequestHandler from 'Infrastructure/DashboardRequestHandler';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
);

interface DataSet {
  label: string;
  data: number[];
  backgroundColor: string;
  hidden?: boolean;
}

interface GraphData {
  labels: string[];
  datasets: DataSet[];
}

const ExpenseCategoryChart = () => {
  const monthStart = -2;
  const monthEnd = 1;

  const [data, setData] = React.useState<GraphData>({
    labels: [],
    datasets: [],
  });
  const [loading, setLoading] = React.useState<boolean>(true);
  const [hideMenu, setHideMenu] = React.useState<boolean>(false);
  const [SelectListData, setSelectListData] = React.useState<ISelectListProps[]>([]);
  const [CategoryListData, setCategoryListData] = React.useState<ISelectListProps[]>([]);

  const onToggleMonth = (id: any) => {
    const selected = SelectListData.find((option) => option.id === id);
    if (selected) {
      selected.isChecked = !selected.isChecked;
      setSelectListData([...SelectListData]);
    }
  }

  const onToggleCategory = (id: any) => {
    const selected = CategoryListData.find((option) => option.id === id);
    if (selected) {
      selected.isChecked = !selected.isChecked;
      setCategoryListData([...CategoryListData]);
    }
  }

  const getMonthName = (idx?: number) => {
    const d = new Date();
    d.setMonth(d.getMonth() + (idx ?? 0));
    return capitalize(d.toLocaleString('default', { month: 'long' }) + ' ' + d.getFullYear());
  }

  const isInitialEnabledMonths = (month: string) => {
    const initialEnabledMonths = [];
    for (let i = monthStart; i < monthEnd; i++) {
      initialEnabledMonths.push(getMonthName(i));
    }
    return initialEnabledMonths.includes(month);
  }

  const loadSelectList = (response: any) => {
    const monthList: ISelectListProps[] = [];
    response.labels.forEach((label: string, index: number) => {
      monthList.push({
        id: label + index,
        label: label,
        isChecked: isInitialEnabledMonths(label),
      });
    });
    setSelectListData(monthList);
  }

  const loadCategoryList = (response: any) => {
    const list: ISelectListProps[] = [];
    response.datasets.forEach((o: any, index: number) => {
      list.push({
        id: o.label + index,
        label: o.label,
        isChecked: true,
      });
    });
    setCategoryListData(list);
  }

  const loadGraphData = () => {
    setLoading(true);
    new DashboardRequestHandler().getChartData().then((response: any) => {
      setData(response);
      loadSelectList(response);
      loadCategoryList(response);
    }).finally(() => {
      setLoading(false);
    });
  }

  React.useEffect(() => {
    loadGraphData();
    // eslint-disable-next-line
  }, []);

  if (loading) {
    return <Loading />
  }

  const getFilterredData = () => {
    const filterredData: GraphData = {
      labels: SelectListData.filter((option) => option.isChecked).map((option) => option.label),
      datasets: data.datasets.map((dataset) => {
        return {
          ...dataset,
          data: dataset.data.filter((value, index) => SelectListData[index].isChecked),
        }
      })
    };

    const filterredDataByCategory: GraphData = {
      labels: filterredData.labels,
      datasets: filterredData.datasets.filter((dataset) => CategoryListData.find((option) => option.label === dataset.label)?.isChecked)
    };

    return filterredDataByCategory;
  }

  const filterredData = getFilterredData();

  const chartSize = "chart s" + filterredData.labels.length;



  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      title: {
        display: false,
      },
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          title: (o: any) => {
            return o[0].label;
          },
          label: (o: any) => {
            return " " + o.dataset.label + ': ' + currencyFormater.format(o.parsed.y);
          }
        }
      },
    },
    layout: {

    },
    scales: {
      y: {
        display: false,
        ticks: {
          display: false
        },
      },
      x: {
        ticks: {
          color: 'rgba(0,0,0,0.5)',
          font: {
            size: 15,
          },
        },
        grid: {
          display: true,
        }
      }
    }
  };


  return (
    <Row>
      <Col lg={12} xxl={12} xxxl={6}>
        <Card>
          <Card.Header>
            <Card.Title as="h5">
              Despesas por Categoria
            </Card.Title>
          </Card.Header>
          <Card.Header>
            <span className='filter' onClick={() => { setHideMenu(!hideMenu) }}>
              <span><FontAwesomeIcon icon={hideMenu ? faFilterCircleXmark : faFilter} /> Filtros</span>
            </span>
          </Card.Header>
          <Card.Body className='chartBox'>
            <div className="chartWrapper">
              <div className={chartSize} onClick={() => setHideMenu(false)} >
                <Bar options={options} data={filterredData} />
              </div>
              <div className={!hideMenu ? 'chartOptions' : 'chartOptions show'}>
                <SelectList options={[CategoryListData, SelectListData]} onChanges={[onToggleCategory, onToggleMonth]} titles={["Categorias", "Meses"]} />
              </div>
            </div>
          </Card.Body>
          <Card.Footer className='chart-footer'>
            {CategoryListData.map((category) => {
              const color = filterredData.datasets.find((dataset) => dataset.label === category.label)?.backgroundColor;
              return (
                <span key={category.id} onClick={() => onToggleCategory(category.id)} className={category.isChecked ? 'chart-legend' : 'chart-legend unselected'} style={{
                  backgroundColor: color,
                }}>{category.label}</span>
              );
            })}
          </Card.Footer>
          <Card.Footer className='chart-footer'>
            {SelectListData.map((month) => {
              return (
                <span key={month.id} onClick={() => onToggleMonth(month.id)} className={month.isChecked ? 'chart-legend' : 'chart-legend unselected'} style={{
                }}>{month.label}</span>
              );
            })}
          </Card.Footer>
        </Card>
      </Col>
    </Row>

  );
}




export default ExpenseCategoryChart
