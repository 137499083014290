import React from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";

export interface Props {
    className?: string;
    variant?: "primary" | "secondary" | "success" | "danger" | "warning" | "info" | "light" | "dark" | "link" | undefined;
}

const BackButton = ({ className="", variant = 'primary' }: Props) => {
    const navigate = useNavigate();

    return (
        <Button className={className} variant={variant} onClick={() => { navigate(-1) }}>
            Voltar
        </Button>
    )

}


export default BackButton;