import React from 'react';
import { Checkbox, List, ListItem, ListItemButton, ListItemText, Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowLeftLong, faArrowRight, faGreaterThan, faLessThan } from '@fortawesome/free-solid-svg-icons';
import { Navbar } from 'react-bootstrap';

export interface ISelectListProps {
  id: any;
  label: string;
  isChecked: boolean;
}

type ChangeSelect = (id: any) => void;
type Title = string;

const SelectList = (props: { options: ISelectListProps[][], onChanges: ChangeSelect[], titles?: Title[] }) => {
  const titles = props.titles || ["Categorias","Calendário"];
  const [option, setOption] = React.useState<string>(titles[0]);



  const changeOption = (id: any) => {
    if (option === titles[0]) {
      setOption(titles[1]);
    } else {
      setOption(titles[0]);
    }
  }

  const props1 = {
    options: props.options[0],
    onChange: props.onChanges[0],
    title: props.titles?.[0],
  }

  const props2 = {
    options: props.options[1],
    onChange: props.onChanges[1],
    title: props.titles?.[1],
  }


  return (
    <List sx={{ overflow: 'auto' }} dense >
      <Navbar className='listHeader'>
        <span onClick={changeOption}><FontAwesomeIcon icon={faArrowLeft} /></span>
        <strong>{option}</strong>
        <span onClick={changeOption}><FontAwesomeIcon icon={faArrowRight} /></span>
      </Navbar>
      { option === titles[0] ?  <CategorySelection {...props1} /> : <MonthSelection {...props2} />}
    </List>
  );
}

const CategorySelection = (props: { options: ISelectListProps[], onChange: (id: any) => void, title?: string }) => {
  const [toggleAll, setToggleAll] = React.useState(true);

  const numberOfChecked = () =>
    props.options.filter((option) => option.isChecked).length;

  const handleToggleAll = () => {
    setToggleAll(!toggleAll);
    props.options.forEach((option) => {
      option.isChecked = toggleAll;
      props.onChange(option.id);
    });
  }

  return <>
    <ListItem
      role="listitem"
      onClick={() => handleToggleAll()}
      disablePadding
      secondaryAction={
        <Checkbox
          onClick={() => handleToggleAll()}
          checked={numberOfChecked() === props.options.length && props.options.length !== 0}
          indeterminate={numberOfChecked() !== props.options.length && numberOfChecked() !== 0}
          disabled={props.options.length === 0}
          id="list-title"
        />
      }
    >
      <ListItemButton
        role="listitem"
      >
        <ListItemText
          secondary={
            <Typography
              sx={{ display: 'inline' }}
              component="span"
              variant="body2"
              color="text.primary"
            >
              {`${numberOfChecked()}/${props.options.length}`}
            </Typography>
          }
        >
          <strong>{props.title}</strong>
        </ListItemText>
      </ListItemButton>
    </ListItem>
    {
      props.options.map((option: ISelectListProps, index: number) => {
        const classSelected = option.isChecked ? '' : 'inactive-selection';
        const labelId = `transfer-list-all-item-${option.id}-label`;
        return (
          <ListItem
            key={index}
            role="listitem"
            onClick={(e) => props.onChange(option.id)}
            disablePadding
            className={classSelected}
            secondaryAction={
              <Checkbox
                checked={option.isChecked}
                disableRipple
              />
            }
          >
            <ListItemButton role="listitem">
              <ListItemText id={labelId}>
                {option.label}
              </ListItemText>
            </ListItemButton>
          </ListItem>
        );
      })
    }
  </>
}


const MonthSelection = (props: { options: ISelectListProps[], onChange: (id: any) => void, title?: string }) => {
  const [toggleAll, setToggleAll] = React.useState(true);

  const numberOfChecked = () =>
    props.options.filter((option) => option.isChecked).length;

  const handleToggleAll = () => {
    setToggleAll(!toggleAll);
    props.options.forEach((option) => {
      option.isChecked = toggleAll;
      props.onChange(option.id);
    });
  }

  const yearToggle = (year: string) => {
    const checkYear = props.options.filter((option) => option.label.slice(-4) === year && option.isChecked).length === 0;
    props.options.forEach((option: any) => {
      if (option.label.slice(-4) === year && option.isChecked !== checkYear) {
        props.onChange(option.id);
      }
    });
  }

  const dataByYear = props.options.reduce((acc: any, option: ISelectListProps) => {
    const year = option.label.slice(-4);
    if (!acc[year]) {
      acc[year] = [];
    }
    acc[year].push({
      id: option.id,
      label: option.label.split(' ')[0],
      isChecked: option.isChecked,
    });
    return acc;
  }, []);

  const numberToggledYear = (year: string) => {
    const yearData = dataByYear[year];
    const yearChecked = yearData.filter((option: any) => option.isChecked).length;
    return yearChecked;
  }

  return (
    <>
      <ListItem
        role="listitem"
        onClick={() => handleToggleAll()}
        disablePadding
        secondaryAction={
          <Checkbox
            onClick={() => handleToggleAll()}
            checked={numberOfChecked() === props.options.length && props.options.length !== 0}
            indeterminate={numberOfChecked() !== props.options.length && numberOfChecked() !== 0}
            disabled={props.options.length === 0}
            id="list-title"
          />
        }
      >
        <ListItemButton
          role="listitem"
        >
          <ListItemText
            secondary={
              <Typography
                sx={{ display: 'inline' }}
                component="span"
                variant="body2"
                color="text.primary"
              >
                {`${numberOfChecked()}/${props.options.length}`}
              </Typography>
            }
          >
            <strong>{props.title}</strong>
          </ListItemText>
        </ListItemButton>
      </ListItem>
      {
        Object.keys(dataByYear).reverse().map((year: string, index: number) => {
          const yearChecked = numberToggledYear(year) === dataByYear[year].length && dataByYear[year].length !== 0;
          const yearIndeterminate = numberToggledYear(year) !== dataByYear[year].length && numberToggledYear(year) !== 0;
          const classYear = yearChecked || yearIndeterminate ? 'listitemyear' : 'listitemyear inactive-selection';
          return (
            <React.Fragment key={index}>
              <ListItem
                className={classYear}
                role="listitem"
                onClick={() => yearToggle(year)}
                disablePadding
                secondaryAction={
                  <Checkbox
                    checked={yearChecked}
                    indeterminate={yearIndeterminate}
                    disabled={dataByYear[year].length === 0}
                    id={`transfer-list-all-item-${year}-label`}
                  />
                }>
                <ListItemButton role="listitemyear">
                  <ListItemText className='listitemyear' >
                    <strong>{year}</strong>
                  </ListItemText>
                </ListItemButton>
              </ListItem>
              {dataByYear[year].reverse().map((option: any, index: number) => {
                const labelId = `transfer-list-all-item-${option.id}-label`;
                const optClass = option.isChecked ? 'sublist' : 'sublist inactive-selection';
                return (
                  <ListItem
                    className={optClass}
                    key={option.id}
                    role="listitemmonth"
                    onClick={(e) => props.onChange(option.id)}
                    disablePadding
                    secondaryAction={
                      <Checkbox
                        checked={option.isChecked}
                        disableRipple
                      />
                    }
                  >
                    <ListItemButton role="listitemmonth">
                      <ListItemText id={labelId} className='sublist'>
                        {option.label}
                      </ListItemText>
                    </ListItemButton>
                  </ListItem>
                );
              })}
            </ React.Fragment>
          )
        })
      }
    </>
  )
}


export default SelectList;