class databaseDataHandler {
    private endpoint = "";

    constructor(endpoint: string) {
        this.endpoint = endpoint;
    }

    endpointData = () => {
        return {
            lastId: 0,
            updatedDate: new Date(),
            data: []
        }
    }

    retrieveUserData = (userData?: any) => {
        if (!userData) {
            return {
                [this.endpoint]: this.endpointData()
            }
        }

        else if (userData[this.endpoint] === undefined) {
            return {
                ...userData,
                [this.endpoint]: this.endpointData()
            }
        }

        return userData;
    }

    retrieveEndpointData = (userData: any) => {
        return userData[this.endpoint]?.data ?? [];
    }
}


export default databaseDataHandler;