import RequestHandler from "../Infrastructure/RequestHandler";
import { Expense, ExpenseFields } from "./Expense";
import { Timestamp } from "firebase/firestore";

const endpoint = "mensais";

export type MonthlyExpense = {
    name: string;
    average: number;
    lastPaidValue: number;
    lastPaidDate: Timestamp;
    dueDay: number;
    paymentMethod: string;
    paidCurrentMonth: boolean;
    category: string;
    totalValue: number;
    numberOfRecords: number;
    percentage: number;
    oldExpense: boolean;
}

type MonthlyExpenseList = {
    [key: string]: MonthlyExpense;
}


const normalizeName = (name: string) => {
    //remove special characters
    const specialChars = /[^a-zA-Z0-9 ]/g;
    name = name.replace(specialChars, "");
    //replace spaces with _
    name = name.replace(/ /g, "");
    //convert to lower case
    return name.toLowerCase();
}

export class MonthlyRequestHandler extends RequestHandler {
    constructor(endpoint: string = "") {
        super("expense");
    }

    allByCategory = async (selectedCategory: string) => {
        const [data, docSnap, docRef] = await this.getUserData();
        const timeAgo = new Date(new Date().getFullYear(), new Date().getMonth() - 3, 1).getTime();


        //mostra apenas os ultimos 3 meses
        const d = data[Expense.endpoint].data
            .map((expense: any) => {
                const expenseDate = expense[ExpenseFields.Date].toDate();
                const isOld = expenseDate.getTime() < timeAgo;
                return isOld ? null : expense;
            })
            .filter((expense: any) => expense !== null);


        const me = d.reduce((acc: MonthlyExpenseList, curr: any) => {
            if (curr[ExpenseFields.Category] === selectedCategory) {
                const id = normalizeName(curr[ExpenseFields.Description]);
                const expenseDate = curr[ExpenseFields.Date].toDate();

                if (acc[id]) {
                    acc[id].totalValue += curr[ExpenseFields.Value];
                    acc[id].numberOfRecords += 1
                } else {
                    acc[id] = {} as MonthlyExpense;
                    acc[id].name = curr[ExpenseFields.Description];
                    acc[id].average = curr[ExpenseFields.Value];
                    acc[id].paidCurrentMonth = false;
                    acc[id].totalValue = curr[ExpenseFields.Value];
                    acc[id].lastPaidDate = curr[ExpenseFields.Date];
                    acc[id].lastPaidValue = curr[ExpenseFields.Value];
                    acc[id].numberOfRecords = 1;
                    acc[id].oldExpense = curr;
                }
                const isOld = expenseDate.getTime() < timeAgo ? true : false;
                const lasDate = acc[id].lastPaidDate?.toDate().getTime() ?? 0;
                const isLast = expenseDate.getTime() > lasDate ? true : false;
                const isPaid = expenseDate.getMonth() === new Date().getMonth() ? true : false;
                acc[id].oldExpense = isOld
                acc[id].category = curr[ExpenseFields.Category];
                acc[id].paymentMethod = curr[ExpenseFields.Type];
                acc[id].dueDay = curr[ExpenseFields.Date].toDate?.().getDate() ?? 0;
                acc[id].lastPaidValue = isLast ? curr[ExpenseFields.Value] : acc[id].lastPaidValue
                acc[id].lastPaidDate = isLast ? curr[ExpenseFields.Date] : acc[id].lastPaidDate
                acc[id].paidCurrentMonth = isPaid ? true : acc[id].paidCurrentMonth
            }
            return acc;
        }, {});

        const totalValueSum = Object.keys(me).reduce((acc, curr) => {
            if (me[curr].isOld) return acc;
            return acc + me[curr].totalValue;
        }, 0);

        const results: MonthlyExpense[] = Object.keys(me).map((key) => {
            const normalized: MonthlyExpense = {
                name: me[key].name,
                average: me[key].average,
                lastPaidValue: me[key].lastPaidValue,
                lastPaidDate: me[key].lastPaidDate,
                dueDay: me[key].dueDay,
                paymentMethod: me[key].paymentMethod,
                paidCurrentMonth: me[key].paidCurrentMonth,
                category: me[key].category,
                totalValue: me[key].totalValue,
                numberOfRecords: me[key].numberOfRecords,
                percentage: me[key].oldExpense ? 0 : (me[key].average / totalValueSum * 100),
                oldExpense: me[key].oldExpense,
            }

            return normalized;
        })

        return results;
    }


    // allByCategory = async (selectedCategory: string) => {
    //     const [data] = await this.getUserData();
    //     const threeMonthsAgo = new Date(new Date().getFullYear(), new Date().getMonth() - 3, 1).getTime();
    
    //     const filterExpenses = (expenses: any[]) => {
    //         return expenses.filter(expense => expense[ExpenseFields.Date].toDate().getTime() >= threeMonthsAgo);
    //     };

    //     const processExpenses = (expenses: any[]) => {
    //         const result: MonthlyExpense[] = [];
    //         const totalValueSum = expenses.reduce((acc, expense) => acc + expense[ExpenseFields.Value], 0);
    
    //         expenses.forEach(expense => {
    //             const id = normalizeName(expense[ExpenseFields.Description]);
    
    //             if (expense[ExpenseFields.Category] === selectedCategory) {
    //                 const id = normalizeName(expense.Description);
    //                 // Processa cada despesa...
    //             }
    //         });
    
    //         return result;
    //     };
    
    //     const filtered = filterExpenses(data[Expense.endpoint].data);

    //     const results = filtered.reduce((acc: MonthlyExpenseList, curr: any) => {
    //         if (curr[ExpenseFields.Category] === selectedCategory) {
    //             const id = normalizeName(curr[ExpenseFields.Description]);
    //             const expenseDate = curr[ExpenseFields.Date].toDate();

    //             if (acc[id]) {
    //                 acc[id].totalValue += curr[ExpenseFields.Value];
    //                 acc[id].numberOfRecords += 1
    //             } else {
    //                 acc[id] = {} as MonthlyExpense;
    //                 acc[id].name = curr[ExpenseFields.Description];
    //                 acc[id].average = curr[ExpenseFields.Value];
    //                 acc[id].paidCurrentMonth = false;
    //                 acc[id].totalValue = curr[ExpenseFields.Value];
    //                 acc[id].lastPaidDate = curr[ExpenseFields.Date];
    //                 acc[id].lastPaidValue = curr[ExpenseFields.Value];
    //                 acc[id].numberOfRecords = 1;
    //                 acc[id].oldExpense = curr;
    //             }
    //             const lastDate = acc[id].lastPaidDate?.toDate().getTime() ?? 0;
    //             const isLast = expenseDate.getTime() > lastDate ? true : false;
    //             const isPaid = expenseDate.getMonth() === new Date().getMonth() ? true : false;
    //             acc[id].category = curr[ExpenseFields.Category];
    //             acc[id].paymentMethod = curr[ExpenseFields.Type];
    //             acc[id].dueDay = curr[ExpenseFields.Date].toDate?.().getDate() ?? 0;
    //             acc[id].lastPaidValue = isLast ? curr[ExpenseFields.Value] : acc[id].lastPaidValue
    //             acc[id].lastPaidDate = isLast ? curr[ExpenseFields.Date] : acc[id].lastPaidDate
    //             acc[id].paidCurrentMonth = isPaid ? true : acc[id].paidCurrentMonth
    //         }
    //         return acc;
    //     }, {});



    //     const results: MonthlyExpense[] = Object.keys(me).map((key) => {
    //         const normalized: MonthlyExpense = {
    //             name: me[key].name,
    //             average: me[key].average,
    //             lastPaidValue: me[key].lastPaidValue,
    //             lastPaidDate: me[key].lastPaidDate,
    //             dueDay: me[key].dueDay,
    //             paymentMethod: me[key].paymentMethod,
    //             paidCurrentMonth: me[key].paidCurrentMonth,
    //             category: me[key].category,
    //             totalValue: me[key].totalValue,
    //             numberOfRecords: me[key].numberOfRecords,
    //             percentage: me[key].oldExpense ? 0 : (me[key].average / totalValueSum * 100),
    //             oldExpense: me[key].oldExpense,
    //         }

    //         return normalized;
    //     })

    //     return results;


    //     const processExpenses = (expenses: any[]) => {
    //         const result: MonthlyExpense[] = [];
    //         const totalValueSum = expenses.reduce((acc, expense) => acc + expense[ExpenseFields.Value], 0);
    
    //         expenses.forEach(expense => {
    //             const id = normalizeName(expense[ExpenseFields.Description]);
    
    //             if (expense[ExpenseFields.Category] === selectedCategory) {
    //                 if () {
    //                     .totalValue += curr[ExpenseFields.Value];
    //                     .numberOfRecords += 1
    //                 } else {
    //                      = {} as MonthlyExpense;
    //                     .name = curr[ExpenseFields.Description];
    //                     .average = curr[ExpenseFields.Value];
    //                     .paidCurrentMonth = false;
    //                     .totalValue = curr[ExpenseFields.Value];
    //                     .lastPaidDate = curr[ExpenseFields.Date];
    //                     .lastPaidValue = curr[ExpenseFields.Value];
    //                     .numberOfRecords = 1;
    //                     .oldExpense = curr;
    //                 }
    //                 const isOld = expenseDate.getTime() < timeAgo ? true : false;
    //                 const lasDate = .lastPaidDate?.toDate().getTime() ?? 0;
    //                 const isLast = expenseDate.getTime() > lasDate ? true : false;
    //                 const isPaid = expenseDate.getMonth() === new Date().getMonth() ? true : false;
    //                 .oldExpense = isOld
    //                 .category = curr[ExpenseFields.Category];
    //                 .paymentMethod = curr[ExpenseFields.Type];
    //                 .dueDay = curr[ExpenseFields.Date].toDate?.().getDate() ?? 0;
    //                 .lastPaidValue = isLast ? curr[ExpenseFields.Value] : .lastPaidValue
    //                 .lastPaidDate = isLast ? curr[ExpenseFields.Date] : .lastPaidDate
    //                 .paidCurrentMonth = isPaid ? true : .paidCurrentMonth
    //             }
    //         });
    
    //         return result;
    //     };
    
    //     const filteredExpenses = filterExpenses(data[Expense.endpoint].data);
    //     const results = processExpenses(filteredExpenses);
    
    //     return results;
    // };
}