import { Col, Form, Placeholder, Row } from "react-bootstrap"

const FormPlaceholder = ({ inputCount = 2 }: { inputCount?: number }) => (
    <Row>
        {Array.from({ length: inputCount }, (_, idx) => (
            <Col key={idx} xs={12}>
                <Placeholder animation="glow">
                    <Form.Group controlId="formDescription">
                        <Placeholder xs={6} bg="secondary" />
                        <Form.Control type="text" disabled />
                    </Form.Group>
                </Placeholder>
            </Col>
        ))}
        <Col xs={12}>
            <div className="flex-between">
                <Placeholder.Button variant="success">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Placeholder.Button>
                <Placeholder bg="secondary">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Placeholder>
            </div>
        </Col>
    </Row>
)

export default FormPlaceholder;