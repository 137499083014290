import { useAlert } from "Providers/AlertProvider";
import { Field, FieldTypes } from "Infrastructure/ModelStructure";
import RequestHandler from "Infrastructure/RequestHandler";
import { currencyToNumber, dateFormater, numberToCurrency } from "Infrastructure/utils";
import { ChangeEvent, useContext, useMemo, useState } from "react";
import { ButtonProps, ListGroup, Table } from "react-bootstrap";
import Loading from "./Loading";
import { useModal } from "Providers/ModalProvider";
import * as XLSX from 'xlsx';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExcel } from "@fortawesome/free-solid-svg-icons";

export interface ExcelActionsProps extends ButtonProps {
    service: RequestHandler;
    fields: Field[];
    excelFileName: string;
    createAction: (data: any) => Promise<any>;
    refreshData?: () => void;
    setLoading?: (loading: boolean) => void;
}
export const defImportFileName = "Importar";

export const ImportExcelButton = (props: ExcelActionsProps) => {
    const modal = useModal()
    const alert = useAlert()
    const [loading, setLoading] = useState<boolean>(false);
    const [importFileName, setImportFileName] = useState<string>(defImportFileName);
    const [massData, setMassData] = useState<any[]>([]);
    const [disabled, setDisabled] = useState<boolean>(false);

    const reset = () => {
        setMassData([]);
        setImportFileName(defImportFileName);
    }

    const ExcelDateToJSDate = (serial: any) => {
        const d = new Date(0, 0, serial - 1);
        return d;
    }

    const formatExcelData = (d: any) => {
        const row: any = {};
        props.fields.forEach(f => {
            if (f.required && !d[f.label] && d[f.label] !== 0) {
                setImportFileName(defImportFileName);
                setMassData([])
                throw new Error(f.label + " é um campo obrigatório.");
            }

            switch (f.type) {
                case FieldTypes.Date:
                    row[f.id] = ExcelDateToJSDate(d[f.label]);
                    break;
                case FieldTypes.Currency:
                    row[f.id] = currencyToNumber(d[f.label]);
                    break;
                default:
                    row[f.id] = d[f.label];
                    break;
            }
        });

        return row;
    }

    const loadMassData = (massData: any[]) => {
        if (massData.length === 0) {
            return;
        }

        props.setLoading ? props.setLoading(true) : setLoading(true);
        props.createAction(massData).then((r) => {
            alert.showAlert({
                type: "success",
                message: "Dados carregados com sucesso!"
            })
            if (props.refreshData) props.refreshData();
        }).catch((e) => {
            alert.showAlert({
                type: "error",
                message: "Ocorreu um erro ao carregar os dados. Por favor, tente novamente."
            })
        }).finally(() => {
            setMassData([]);
            if (props.refreshData) {
                props.refreshData();
            }
            setImportFileName(defImportFileName);
            props.setLoading ? props.setLoading(false) : setLoading(false);
        });
    }


    const renderMassExcelData = (data: any[]) => {
        setLoading(true);
        const massDataTmp = data.map((d: any) => {
            return formatExcelData(d);
        });

        if (massDataTmp.includes(null) || massDataTmp.length === 0) {
            setImportFileName(defImportFileName);
            setMassData([])
            throw new Error("O arquivo não está no formato correto.");
        }

        setMassData(massDataTmp as any[]);


        modal.showModal({
            title: "Deseja carregar os dados a seguir?",
            message: <ExcelList data={massDataTmp} fields={props.fields} />,
            onConfirm: () => loadMassData(massDataTmp),
            onCancel: reset,
            size: "xl"
        });

        setLoading(false);
    }


    const excelDataToObject = (data: any) => {
        const headers = data[0];
        const rows = data.slice(1);
        const objects = rows.map((row: any) => {
            const obj: any = {};
            headers.forEach((header: any, index: number) => {
                obj[header] = row[index];
            });
            return obj;
        }
        );
        return objects;
    };

    const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files && event.target.files[0];
        alert.clearAlert();
        if (file) {
            setImportFileName(`${file.name}`);
            setDisabled(true);
            const reader = new FileReader();
            reader.onload = (e) => {
                const workbook = XLSX.read(e.target?.result, { type: 'binary' });
                const worksheet = workbook.Sheets[workbook.SheetNames[0]];
                const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
                try {
                    renderMassExcelData(excelDataToObject(jsonData));
                } catch (error) {
                    alert.showAlert({
                        type: "error",
                        message: (error as Error).message
                    })
                }
                setDisabled(false);
            };
            reader.readAsBinaryString(file);
        }
    };


    if (loading) {
        return <Loading />
    }

    const className = defImportFileName === importFileName ? 'btn btn-secondary' : 'btn btn-warning';
    const isSmall = props.size === 'sm' ? 'btn-sm' : '';

    return (
        <label htmlFor="loadExcel" className={className + " " + isSmall} style={{ cursor: "pointer" }}><FontAwesomeIcon icon={faFileExcel} /><span className='m-1'>{importFileName}</span>
            <input className='d-none' disabled={disabled} id="loadExcel" type="file" accept=".xlsx, .xls" onChange={handleFileChange} />
        </label>
    );
}

const ExcelList = (props: { data: any[], fields: Field[] }) => {
    const showField = (field: Field, data: any, r: any) => {
        switch (field.type) {
            case FieldTypes.Currency:
                return numberToCurrency(data);
            case FieldTypes.Date:
                return dateFormater.format(data);
            case FieldTypes.Checkbox:
                return data ? 'Sim' : 'Não';
            default:
                return data;
        }
    }

    if (props.data.length === 0) { return <></> }

    return (
        <ListGroup variant="flush">
            <ListGroup.Item className="p-0">
                <Table responsive>
                    <thead>
                        <tr>
                            {
                                props.fields.map((field, index) =>
                                    <th key={index} style={{ textAlign: field.position ?? 'left' }}>{field.label}</th>
                                )
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {
                            props.data.map((res, index) =>
                                <tr key={index}>
                                    {
                                        props.fields.map((field, index) =>
                                            <td key={index} style={{ textAlign: field.position ?? 'left', minWidth: '150px' }}>
                                                {showField(field, res[field.id], res)}
                                            </td>
                                        )
                                    }
                                </tr>
                            )
                        }
                    </tbody>
                </Table>
            </ListGroup.Item>
        </ListGroup>
    )
}



