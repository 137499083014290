import RequestHandler from "./RequestHandler";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { fixDecimals, getDateFromDatabaseToJsDate } from "./utils";

export enum FieldTypes {
    String = "string",
    Number = "number",
    Currency = "currency",
    Date = "date",
    Checkbox = "checkbox",
    AutoComplete = "autoComplete",
}

export interface Field {
    label: string;
    id: string;
    viewId?: string;
    relateTo?: string;
    type: FieldTypes;
    required?: boolean;
    source?: () => Promise<any>;
    position?: 'right' | 'left' | 'center';
    question?: string;
    fieldSize?: number;
    erorrMessage?: string;
    defaultValue?: any;
    hide?: boolean;
    autoFocus?: boolean;
    autocompleteList?: (requester: string) => Promise<string[]>;
}



export interface ModelFields {
    [key: string]: Field;
}



export interface IModelSetup {
    endpoint: string;
    service?: RequestHandler;
    title: string;
    plural: string;
    article: string;
    defaultPath: string;
    fields: ModelFields;
    orderBy?: string;
    orderDirection?: "asc" | "desc" | undefined;
    createOnList?: boolean;
    autocompleteList?: (requester: string) => Promise<any[]>;
    element?: React.ReactNode;
    isMenu?: boolean;
    menuIcon?: IconProp;
    isSetup?: boolean;
}


export const generateFormFields = (modelFields: ModelFields) => {
    const fields: Field[] = [];
    Object.keys(modelFields).forEach((key) => {
        const f = modelFields[key];
        if (f.hide !== true) fields.push(f as Field);
    });
    return fields;
}

export const generateTableFields = (modelFields: ModelFields) => {
    const fields: Field[] = [];
    Object.keys(modelFields).forEach((key) => {
        const f = modelFields[key];
        if (f.hide !== true && f.required) fields.push(f as Field);
    });
    return fields;
}

export const getDefaultService = (endpoint: string) => {
    return new RequestHandler(endpoint)
}

export const getFormData = (fields: ModelFields, data? : any) => {
    const form: any = {};
    if(data?.id) form.id = data.id;
    Object.keys(fields).forEach((key) => {
        const f = fields[key];
        form[f.id] = data ? formatDatabaseDataToForm(data[f.id], f) : f.defaultValue ?? "";
    });
    return form;
}

const formatDatabaseDataToForm = (data: any, field: Field) : any => {
    switch (field.type) {
        case FieldTypes.Date:
            return getDateFromDatabaseToJsDate(data);
        case FieldTypes.Currency:
            return fixDecimals(data);
        default:
            return data;
    }
}