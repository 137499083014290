import { Navigate } from "react-router-dom";
import LoginTemplate from "Templates/LoginTemplate";
import React from "react";
import { useAuth } from "Providers/AuthProvider";
import DefaultTemplate from "Templates/DefaultTemplate";
import { AlertProvider } from "Providers/AlertProvider";
import Login from "Views/Public/Login";
import DynamicRoute from "Templates/Components/Dynamic/DynamicRoute";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import NotFound from "Views/NotFound";
import PrivateRoutes from "Views/PrivateRoutes";
import PublicRoutes from "Views/PublicRoutes";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { IModelSetup } from "Infrastructure/ModelStructure";
import { Repositories } from "Repository/Repositories";
import { uid } from "./utils";

type ComponentRouteProps = {
    Component: React.FC<any>;
    rest?: any;
}

type ModelRouteProps = {
    routeElement: IBaseElementRoute | IBaseDynamicRoute;
    rest?: any;
}

type IRoute = {
    path: string;
    title: string;
    type?: 'public' | 'private' | 'login';
    isMenu?: boolean;
    menuIcon?: IconProp;
    params?: any;
}

export type IBaseElementRoute = IRoute & {
    element: React.FC<any>;
}

export type IBaseDynamicRoute = {
    model: IModelSetup;
}

export type IRouteMap = {
    [key: string]: IBaseElementRoute | IBaseDynamicRoute;
}

export const LoginRoute = (props: ComponentRouteProps | ModelRouteProps) => {
    const auth = useAuth();

    if (auth.isLoggedIn()) {
        return <Navigate to={"/"} />
    }

    const componentProps = {
        ...props,
    }

    return (
        <LoginTemplate>
            <AlertProvider>
                <RouteComponent {...componentProps} />
            </AlertProvider>
        </LoginTemplate>
    )
}

export const PrivateRoute = (props: ComponentRouteProps | ModelRouteProps) => {
    const auth = useAuth();

    if (!auth.isLoggedIn()) {
        return <LoginTemplate>
            <AlertProvider>
                <RouteComponent Component={Login} />
            </AlertProvider>
        </LoginTemplate>
    }

    const componentProps = {
        ...props,
    }

    return (
        <DefaultTemplate>
            <AlertProvider>
                <RouteComponent {...componentProps} />
            </AlertProvider>
        </DefaultTemplate>
    )
}

export const PublicRoute = (props: ComponentRouteProps | ModelRouteProps) => {
    const componentProps = {
        ...props,
    }


    return (
        <AlertProvider>
            <RouteComponent {...componentProps} />
        </AlertProvider>
    )
}


const RouteComponent = (props: ComponentRouteProps | ModelRouteProps) => {
    const anyProps = props as any;
    console.log(props)
    if(anyProps?.routeElement?.model) {
        return <DynamicRoute {...anyProps.routeElement.model} />
    } else if (anyProps?.routeElement) {
        return <anyProps.routeElement.element {...props.rest} />
    } else if (anyProps?.Component) {
        return <anyProps.Component {...props.rest} />
    }

    return <NotFound />
}

export const generateRouteMap = () => {
    const routeMap : IRouteMap = {
        ...PublicRoutes,
        ...PrivateRoutes
    }

    Repositories.forEach((repo) => {
        routeMap[repo.title] = {
            model: repo
        }
    })

    return routeMap;
}


export const AppRoutes = () => {
    const routeMap = generateRouteMap();
    return (
      <Router>
        <Routes>
          {
            Object.keys(routeMap).map((key, index) => {
              const item : any = routeMap[key];
              if(item.model) return <Route key={index} path={item.model.defaultPath + "/*"} element={<AppRouteElement {...item} />} />
              return <Route key={uid()} path={item.path + "/*"} element={<AppRouteElement {...item}  />} />
            })
          }
          <Route path="*" element={<PublicRoute Component={NotFound} />} />
        </Routes>
      </Router>
    )
  }
  
  const AppRouteElement = (item: any) => {
    switch (item.type) {
      case 'public':
        return <PublicRoute routeElement={item} key={uid()}/>
      case 'login':
        return <LoginRoute routeElement={item} key={uid()}/>
      default:
        return <PrivateRoute routeElement={item} key={uid()}/>
    }
  }

