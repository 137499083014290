import TopMenu from 'Templates/Menu/TopMenu';
import SideMenu from "./Menu/SideMenu";
import { useAuth } from 'Providers/AuthProvider';
import { useContext } from 'react';


type Props = {
    doLogout?: () => void;
    children?: React.ReactNode;
};


const DefaultTemplate = ({ children }: Props) => {
    const auth = useAuth();
    const user = auth.userData();

    return (
        <div className="main-wrapper">
            <TopMenu doLogout={auth.Logout} email={user?.email} />
            <div className="sidebar-wrapper d-none d-lg-block">
                <SideMenu doLogout={auth.Logout} email={user?.email}  />
            </div>
            <div className="content-wrapper">
                {children}
            </div>
        </div>
    );
}

export default DefaultTemplate;