import { Button, Card, Col, Form, Navbar, Row } from "react-bootstrap";
import FormPlaceholder from "../FormPlaceholder";
import BackLink from "Templates/Components/BackLink";
import { useState } from "react";
import { IModelSetup, ModelFields, generateFormFields } from "Infrastructure/ModelStructure";
import DynamicInput from "./Inputs/DynamicInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faSave } from "@fortawesome/free-solid-svg-icons";


export type DynamicFormProps = {
    model: IModelSetup;
    title: string;
    size?: number;
    validated?: boolean;
    actionLabel: string;
    loading: boolean;
    handleSubmit: (event: any) => void;
    onChangeField: (value: React.SetStateAction<any>) => void;
    data: any;
    fields: ModelFields;
    tableForm?: boolean;
}

export const DynamicForm = (props: DynamicFormProps) => {
    const [errors, setErrors] = useState<any>({});

    const onSubmit = (event: any) => {
        event?.preventDefault();
        if (validateForm()) {
            props.handleSubmit(event);
        }
    }

    const size = props.size ? props.size : 12;

    const validateForm = () => {
        let validForm = true;
        let e = {};
        Object.keys(props.model.fields).map((f, index) => {
            const field = props.model.fields[f];
            if (field.required && !props.data[f]) {
                validForm = false;
                e = {
                    ...e,
                    [f]: "Campo obrigatório."
                }
            }
        })
        setErrors(e)
        return validForm;
    }

    const validateField = (field: string, value: any) => {
        if (!props.fields[field]) return;
        if (props.fields[field].required && !value) {
            setErrors({ ...errors, [field]: "Campo obrigatório." });
        } else {
            setErrors((prev: any) => {
                delete prev[field];
                return prev;
            });
        }
    }

    const changeField = (field: string, value: any) => {
        validateField(field, value);
        props.onChangeField({
            ...props.data,
            [field]: value,
        })
    }

    if (props.tableForm) return <TableForm
        title={props.title}
        size={size}
        loading={props.loading}
        actionLabel={props.actionLabel}
        model={props.model}
        data={props.data}
        onSubmit={onSubmit}
        changeField={changeField}
        errors={errors}
    />

    return <DefaultForm
        title={props.title}
        size={size}
        loading={props.loading}
        actionLabel={props.actionLabel}
        model={props.model}
        data={props.data}
        onSubmit={onSubmit}
        changeField={changeField}
        errors={errors}
    />
}

const DefaultForm = (props: {
    title: string;
    size: number;
    loading: boolean;
    actionLabel: string;
    model: IModelSetup;
    data: any;
    onSubmit: (event: any) => void;
    changeField: (field: string, value: any) => void;
    errors: any;
}) => {
    return (
        <Row>
            <Col xl={props.size}>
                <Card>
                    <Card.Header>
                        <Navbar>
                            <Card.Title as="h5">
                                {props.title}
                            </Card.Title>
                        </Navbar>
                    </Card.Header>
                    <Card.Body>
                        {props.loading ?
                            <FormPlaceholder inputCount={generateFormFields(props.model.fields).length} />
                            :
                            <Form onSubmit={props.onSubmit}>
                                <Row>
                                    {
                                        Object.keys(props.model.fields).map((f, index) => {
                                            const field = props.model.fields[f];
                                            const key = props.title + f + index;

                                            return <DynamicInput
                                                key={key}
                                                field={field}
                                                value={props.data[f]}
                                                onChange={props.changeField}
                                                disabled={props.loading}
                                                errors={props.errors[f]}
                                            />
                                        })
                                    }
                                    <Col xs={12}>
                                        <div className="flex-between">
                                            <Button type="submit" variant="success" disabled={props.loading}>
                                                {props.loading ? "Carregando..." : props.actionLabel}
                                            </Button>
                                            <BackLink />
                                        </div>
                                    </Col>
                                </Row>
                            </Form>
                        }
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    )
}

const TableForm = (props: {
    title: string;
    size: number;
    loading: boolean;
    actionLabel: string;
    model: IModelSetup;
    data: any;
    onSubmit: (event: any) => void;
    changeField: (field: string, value: any) => void;
    errors: any;
    enterKey?: () => void;
}) => {
    return (
        <tbody>
            <tr className="tableForm">
                {
                    Object.keys(props.model.fields).map((f, index) => {
                        const field = props.model.fields[f];
                        const key = props.title + f + index;
                        if (!field.required || field.hide) return null;

                        return <td key={index}>
                            <DynamicInput
                                key={key}
                                field={field}
                                autoFocus={field.autoFocus}
                                value={props.data[f]}
                                onChange={props.changeField}
                                disabled={props.loading}
                                errors={props.errors[f]}
                                enterKey={props.enterKey}
                                noLabel
                                small
                            />
                        </td>
                    })
                }
                <td key={"action"} className="p-1 action">
                    <Button type="submit" variant="success" style={{
                        width: "100%",
                    }} disabled={props.loading} onClick={props.onSubmit}>
                        <FontAwesomeIcon icon={faPlus} />
                    </Button>
                </td>
            </tr>

        </tbody>
    )
}