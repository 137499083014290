import logo from 'assets/image/gp_400px.png'
import { NavLink, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOut } from '@fortawesome/free-solid-svg-icons';
import { Button } from 'react-bootstrap';
import MenuList from './MenuList';
import { List, ListItem, ListItemButton, ListItemText } from '@mui/material';

type Props = {
    doLogout?: () => void;
    email?: string | null | undefined;
    isLoading?: boolean;
};

const SideMenu = ({ doLogout, email, isLoading }: Props) => {
    const navigate = useNavigate();
    return (
        <div className='sidebar'>
            <div className="sidebar-top navbar">
                <List sx={{ width: 1, margin: 0, padding: 0 }}>
                    <ListItem role="menuItem" className="sidebar-brand" onClick={() => navigate("/")}>
                        <img src={logo} alt="GoldPocket" />
                    </ListItem>
                    <MenuList />
                </List>
            </div>
            <div className="sidebar-bottom">
                <div className="sidebar-logout">
                    {
                        isLoading ? null : (<span>Olá, {email}!</span>)
                    }
                    <Button className="btn-logout" variant='link' onClick={doLogout}>
                        <FontAwesomeIcon icon={faSignOut} className="p-1" />
                    </Button>
                </div>
            </div>
        </div>
    );
}

export default SideMenu;