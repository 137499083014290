import { IRouteMap } from "Infrastructure/RouteConfiguration";
import Home from "./Public/Home";
import Login from "./Public/Login";
import Register from "./Public/Register";

const PublicRoutes : IRouteMap = {
    Home: {
        path: "/Home",
        title: "Home",
        type: "public",
        element: Home
    },
    Login: {
        path: "/Login",
        title: "Login",
        type: "login",
        element: Login
    },
    Register: {
        path: "/Register",
        title: "Register",
        type: "login",
        element: Register
    },
}


export default PublicRoutes;