import { AlertItem, AlertOptions } from "Templates/Components/Alert";
import React from "react";
import { useState } from "react";


type AlertContext = {
    showAlert: (options: AlertOptions) => void;
    clearAlert: () => void;
};


const AlertContext = React.createContext<AlertContext>({
    showAlert: () => { },
    clearAlert: () => { },
});

export const useAlert = () => {
    return React.useContext(AlertContext);
}

export const AlertProvider = (props: { children: React.ReactNode; }) => {
    const [alert, setAlert] = useState<AlertOptions>();
    const [timeoutId, setTimeoutId] = useState<any>();

    const showAlert = (options: AlertOptions) => {
        setAlert(undefined);
        setAlert({
            ...options,
            onClose: () => {
                options.onClose?.();
                clearAlert();
            },
        });

        if (timeoutId) {
            clearTimeout(timeoutId);
        }

        const id = setTimeout(() => {
            clearAlert();
        }, options.timeout ?? 5000);

        setTimeoutId(id);
    };

    const clearAlert = () => {
        if (timeoutId) {
            clearTimeout(timeoutId);
        }
        setAlert(undefined);
    };

    return (
        <AlertContext.Provider value={{ showAlert, clearAlert }}>
            {alert ? <AlertItem {...alert} /> : null}
            {props.children}
        </AlertContext.Provider>
    );
}