import { currencyFormater } from 'Infrastructure/utils';
import { MonthlyExpense } from 'Repository/Monthly';
import ExpenseByCategory from 'Templates/Components/ExpenseByCategory';


const MonthlyExpenses = () => {
    const headers = [
        { field: "name", label: "Nome" },
        { field: "average", label: "Média" },
        { field: "lastPaidValue", label: "Último" },
        { field: "lastPaidDate", label: "Data" },
        { field: "dueDay", label: "Dia" },
        { field: "paidCurrentMonth", label: "Pago" },
        { field: "paymentMethod", label: "Tipo" },
        { field: "percentage", label: "%" }
    ];

    const expenseRender = (expense: MonthlyExpense, index: number) => {
        const paidClass = expense.paidCurrentMonth ? "cell-success" : "cell-danger";
        return (
            <tr key={index}>
                <td>{expense.name}</td>
                <td>{currencyFormater.format(expense.average)}</td>
                <td>{currencyFormater.format(expense.lastPaidValue)}</td>
                <td>{expense.lastPaidDate.toDate?.().toLocaleDateString() ?? ""}</td>
                <td>{expense.dueDay.toString()}</td>
                <td className={paidClass}>{expense.paidCurrentMonth ? "Sim" : "Não"}</td>
                <td>{expense.paymentMethod}</td>
                <td>{expense.percentage.toFixed(2) + "%"}</td>
            </tr>
        )
    }

    return <ExpenseByCategory categoryName="Mensal" plural="Mensais" headers={headers} expenseRender={expenseRender} showPending={true} />
};


export default MonthlyExpenses;