import { DynamicInputProps } from "./DynamicInput"
import { TextField } from "@mui/material"

const DynamicTextInput = (props: DynamicInputProps) => {
    const hasError = props.errors !== undefined && props.errors !== "";
    return <TextField
        error={hasError}
        type={props.field.type}
        name={props.field.id}
        value={props.value}
        onChange={(e) => (props.onChange) ? props.onChange(props.field.id, e.target.value) : null}
        disabled={props.disabled}
        fullWidth
        autoFocus={props.autoFocus}
        size={props.small ? "small" : "medium"}
        onKeyUp={(e) => {
            if (e.key === "Enter") {
                if (props.enterKey) props.enterKey();
            }
        }}
    />
}

export default DynamicTextInput