import { auth, firestore } from "Infrastructure/firebase-config";
import { doc, getDoc, setDoc, updateDoc } from "firebase/firestore";
import databaseDataHandler from "./DatabaseHandler";
import { localStorageHandler } from "./StorageHandler";

const USE_LOCAL_STORAGE = true;

class RequestHandler {
    protected endpoint = "";
    protected useLocalStorage = USE_LOCAL_STORAGE;
    private appName = "goldpocket";
    protected localStorageHandler: localStorageHandler;
    protected dataHandler: databaseDataHandler;

    constructor(endpoint: string) {
        this.endpoint = endpoint;
        this.localStorageHandler = new localStorageHandler(this.appName, this.userId());
        this.dataHandler = new databaseDataHandler(endpoint);
    }

    public refreshData = (requester: string) => {
        this.localStorageHandler.clearStoredData();
        return this.all(requester);
    }

    public userId = () => {
        return auth.currentUser?.uid ?? "0";
    }

    public getUserData = async () => {
        const docRef = doc(firestore, "goldpocket", this.userId());
        const docSnap = await getDoc(docRef);
        const data: any = this.dataHandler.retrieveUserData(docSnap.data());
        return [data, docSnap, docRef];
    }

    public all = async (requester?: string): Promise<any[]> => {
        if (this.useLocalStorage && this.localStorageHandler.isStored()) {
            const data = this.localStorageHandler.getStoredData();
            return this.dataHandler.retrieveEndpointData(data);
        }
        else {
            const [data] = await this.getUserData();
            this.localStorageHandler.storeData(data);
            return this.dataHandler.retrieveEndpointData(data);
        }
    }

    public get = async (id: string, requester: string) => {
        const list = await this.all(requester);
        const item = list.find((i: any) => i.id.toString() === id);
        if (!item && this.useLocalStorage) {
            const refreshedList = await this.refreshData(requester);
            return refreshedList.find((i: any) => i.id.toString() === id);
        }
        return item;
    }

    public saveDocument = async (data: any | any[] | null, docData: any, document: any, docRef: any) => {
        if (data) {
            if (Array.isArray(data)) {
                data.forEach((d) => { this.defineRecord(d, docData) });
            }
            else {
                this.defineRecord(data, docData);
            }
        }


        if (!document.exists()) {
            await setDoc(docRef, docData);
            this.localStorageHandler.storeData(docData);
        }
        else {
            await updateDoc(docRef, docData);
            this.localStorageHandler.storeData(docData);
        }
    }

    public defineRecord = (data: any, doc: any) => {
        const endpointData = doc[this.endpoint].data;
        const index = endpointData.findIndex((i: any) => i.id === data.id);

        if (index >= 0) {
            doc[this.endpoint].data[index] = data;
        }
        else {
            if (!data.id) data.id = doc[this.endpoint].lastId + 1;
            doc[this.endpoint].lastId = data.id;
            doc[this.endpoint].updatedDate = new Date();
            doc[this.endpoint].data.push(data);
        }
    }


    public create = async (data: any | any[]) => {
        const [docData, document, docRef] = await this.getUserData();
        return await this.saveDocument(data, docData, document, docRef);
    }


    public update = async (data: any) => {
        const [docData, document, docRef] = await this.getUserData();
        return await this.saveDocument(data, docData, document, docRef);
    }

    public delete = async (id: string, requester: string) => {
        const [data, document, docRef] = await this.getUserData();
        const index = data[this.endpoint].data.findIndex((i: any) => i.id.toString() === id);
        if (index >= 0) {
            data[this.endpoint].data.splice(index, 1);
            return await this.saveDocument(null, data, document, docRef);
        }
        throw new Error("Record not found");
    }
}

export default RequestHandler;