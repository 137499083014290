import { Button } from "react-bootstrap";
import * as XLSX from 'xlsx';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExport } from "@fortawesome/free-solid-svg-icons";
import { ExcelActionsProps } from "./ImportExcelButton";


export const ExportExcelButton = (props: ExcelActionsProps) => {
    const fileName = props.excelFileName ?? "table_data";

    const exportData = async () => {
        const data = await generateExcelData()
        const worksheet = XLSX.utils.json_to_sheet(data);
        const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

        /* create a blob and save it to the user's device */
        const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = `GoldPocket - ${fileName}.xlsx`;
        link.click();
    };

    const generateExcelData = async () => {
        const docs = await props.service.all("excel actions");
        const results = docs.map((doc: any) => {
            let res: any = {}
            props.fields.forEach(f => {
                const data = doc[f.id];
                if (data?.seconds) {
                    const date = new Date(data.seconds * 1000);
                    const converted = 25569.0 + ((date.getTime() - (date.getTimezoneOffset() * 60 * 1000)) / (1000 * 60 * 60 * 24));
                    res[f.label] = converted;
                }
                else {
                    res[f.label] = data;
                }
            })
            return res;
        })

        return results;
    }

    return (
        <Button variant='primary' size={props.size} onClick={exportData}><FontAwesomeIcon icon={faFileExport} /><span className='m-1'>Exportar</span></Button>
    );
}


export default ExportExcelButton;
