import logo from 'assets/image/gp_400px.png'

type Props = {
    children?: React.ReactNode;
};

const LoginTemplate = ({ children }: Props) => {
    return (
        <div className='centered-box'>
            <div className="box bg-white-transparent">
                <div className="login-brand">
                    <img src={logo} alt='GoldPocket' />
                </div>
                {children}
            </div>
        </div>
    );
}

export default LoginTemplate;